import { css } from "styled-components";

export const BREAKPOINT_MEDIUM = 640;
export const BREAKPOINT_MEDIUM_LARGE = 768;
export const BREAKPOINT_LARGE = 1024;
export const BREAKPOINT_EXTRA_LARGE = 1264;

export const BREAKPOINT_TO_MEDIUM = BREAKPOINT_MEDIUM - 1;
export const BREAKPOINT_TO_MEDIUM_LARGE = BREAKPOINT_MEDIUM_LARGE - 1;
export const BREAKPOINT_TO_LARGE = BREAKPOINT_LARGE - 1;
export const BREAKPOINT_TO_EXTRA_LARGE = BREAKPOINT_EXTRA_LARGE - 1;

const query = (min) => {
  if (Number.isInteger(min)) {
    return `@media (min-width: ${min}px)`;
  }
  return false;
};

export const createMediaQuery = (min) => {
  return (...args) => css`
    ${() => {
      return css`
        ${query(min)} {
          ${css(...args)}
        }
      `;
    }}
  `;
};

const media = {
  medium: createMediaQuery(BREAKPOINT_MEDIUM),
  mediumLarge: createMediaQuery(BREAKPOINT_MEDIUM_LARGE),
  large: createMediaQuery(BREAKPOINT_LARGE),
  extraLarge: createMediaQuery(BREAKPOINT_EXTRA_LARGE),
};

export default media;
