import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withAuth } from "@usn/atlas-auth";
import { useToast } from "components/Toast";
import {
  setSingleFavorite,
  setMultipleFavorites,
  getSavedFavorites,
} from "./favoritingHelper";

export const FavoritingContext = React.createContext(null);

const FavoritingProviderUnconnected = ({ loggedIn, children }) => {
  const [isToastOpen, openToast, closeToast] = useToast();
  const [toastState, setToastState] = useState(); // undefined or 'saving' or 'removing'

  const [favoritedSailingIds, setFavoritedSailingIds] = useState([]);
  useEffect(() => {
    async function saveSailing(odysseusId) {
      return setSingleFavorite(odysseusId, true);
    }
    async function getFavorited() {
      const odysseusIdToSave = window.sessionStorage.getItem("saveAfterLogin");
      if (odysseusIdToSave) {
        const hasSucessfullyUpdated = await saveSailing(odysseusIdToSave);
        if (hasSucessfullyUpdated) {
          setToastState("saving");
          openToast();
          window.sessionStorage.removeItem("saveAfterLogin");
        } else {
          // TODO Handle error somehow
        }
      }
      const { error, results } = await getSavedFavorites();
      if (!error) {
        setFavoritedSailingIds(results);
      }
      // TODO Handle error somehow
    }
    if (loggedIn) {
      getFavorited();
    }
  }, [loggedIn]);

  /* eslint-disable no-underscore-dangle */
  const _setSingleFavorite = async (odysseusId, isToBeFavorited) => {
    const hasSucessfullyUpdated = await setSingleFavorite(
      odysseusId,
      isToBeFavorited
    );
    if (hasSucessfullyUpdated) {
      setToastState(isToBeFavorited ? "saving" : "removing");
      openToast();
      const { error, results } = await getSavedFavorites();
      if (!error) {
        setFavoritedSailingIds(results);
      }
    }
    return hasSucessfullyUpdated;
  };

  const _setMultipleFavorites = async (odysseusIds, isToBeFavorited) => {
    const hasSucessfullyUpdated = await setMultipleFavorites(
      odysseusIds,
      isToBeFavorited
    );
    if (hasSucessfullyUpdated) {
      setToastState(isToBeFavorited ? "saving" : "removing");
      openToast();
      const { error, results } = await getSavedFavorites();
      if (!error) {
        setFavoritedSailingIds(results);
      }
    }
    return hasSucessfullyUpdated;
  };
  /* eslint-enable no-underscore-dangle */

  const saveAfterLogin = (odysseusId) => {
    window.sessionStorage.setItem("saveAfterLogin", odysseusId);
  };

  const cancelSaveAfterLogin = () => {
    window.sessionStorage.removeItem("saveAfterLogin");
  };

  const onToastClose = () => {
    closeToast();
    setToastState();
  };

  const value = useMemo(
    () => ({
      favoritedSailingIds,
      setSingleFavorite: _setSingleFavorite,
      setMultipleFavorites: _setMultipleFavorites,
      saveAfterLogin,
      cancelSaveAfterLogin,
      isToastOpen,
      onToastClose,
      toastState,
    }),
    [favoritedSailingIds, isToastOpen, toastState]
  );

  return (
    <FavoritingContext.Provider value={value}>
      {children}
    </FavoritingContext.Provider>
  );
};

FavoritingProviderUnconnected.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.bool,
};

export const FavoritingProvider = withAuth(FavoritingProviderUnconnected);
