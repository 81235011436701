const home = "https://www.gotosea.com";

export default {
  // label for breadcrumb name, file for Link href, path for Link as or anchor href

  odysseusBooking: () => {
    return {
      path: "https://book.gotosea.com/web/cruises/details.aspx",
    };
  },

  myCruiseBookings: () => {
    return {
      path: "https://book.gotosea.com/web/customer/bookings.aspx?siid=330075",
    };
  },

  userAgreement: () => {
    return {
      file: "/info/user-agreement.js",
      path: "/user-agreement",
      canonicalUrl: `${home}/user-agreement`,
    };
  },

  privacyPolicy: () => {
    return {
      path: "https://www.usnews.com/features/info/privacy",
    };
  },

  search: () => {
    return {
      file: "/search/index.js",
      path: "/search",
      canonicalUrl: `${home}/search`,
    };
  },

  cannedSearch: (urlname) => {
    return {
      file: "/[canned_search_urlname].js",
      path: `/${urlname}`,
      canonicalUrl: `${home}/${urlname}`,
    };
  },

  profile: (urlname, id) => {
    return {
      path: `/cruises/${urlname}-${id}`,
      canonicalUrl: `${home}/cruises/${urlname}-${id}`,
    };
  },

  favorites: () => {
    return {
      label: "Saved Sailings",
      file: "/my-cruises/index.js",
      path: `/my-cruises`,
      canonicalUrl: `${home}/my-cruises`,
    };
  },

  communicationPreferences: () => {
    return {
      label: "Communication Preferences",
      file: "/communication-preferences/index.js",
      path: "/communication-preferences",
      canonicalUrl: `${home}/communication-preferences`,
    };
  },

  reviews: () => {
    return {
      label: "Reviews and Testimonials",
      file: "/customer-reviews/index.js",
      path: "/customer-reviews",
      canonicalUrl: `${home}/customer-reviews`,
    };
  },

  affiliate: () => {
    return {
      label: "Affiliate",
      file: "/affiliate/index.js",
      path: "/affiliate",
      canonicalUrl: `${home}/affiliate`,
    };
  },

  about: () => {
    return {
      label: "About Us",
      file: "/about-us/index.js",
      path: "/about-us",
      canonicalUrl: `${home}/about-us`,
    };
  },

  home: () => {
    return {
      label: "Home",
      file: "/index.js",
      path: "/",
      canonicalUrl: home,
    };
  },
};
