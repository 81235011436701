export const black70 = "#333333";
export const gray05 = "#f4f7f9";
export const gray10 = "#e6e6e6";
export const gray15 = "#cccccc";
export const gray30 = "#888888";
export const gray50 = "#666666";
export const blue15 = "#b2dcff";
export const blue60 = "#164d99";
export const green01 = "#f7fcfa";
export const green03 = "#dff4ec";
export const green05 = "#b3e6d3";
export const green10 = "#5fc9a2";
export const green60 = "#1d616a";
export const green90 = "#003c36";
export const red20 = "#f8c2d5";
export const red50 = "#ad0042";
export const red90 = "#8d0037";
export const purple30 = "#5f60b0";
export const purple60 = "#383871";
export const yellow05 = "#fee8a6";
export const yellow50 = "#efbc22";
export const yellow60 = "#ffbc27";
export const yellow70 = "#ffa900";
export const white00 = "#ffffff";
