import { css } from "styled-components";
import isInteger from "lodash/isInteger";
import media from "styles/media";

/* eslint-disable */
const spacing = [
  "0rem",       // 0
  "0.125rem",   // 1
  "0.25rem",    // 2
  "0.5rem",     // 3
  "1rem",       // 4
  "2rem",       // 5
  "4rem",       // 6
  "8rem",       // 7
];
/* eslint-enable */

export const spacingMixin = css`
  ${(props) =>
    isInteger(props.$spacing) &&
    `
      margin-bottom: ${spacing[props.$spacing]};
  `}

  ${(props) =>
    isInteger(props.$spacingMedium) &&
    media.medium`
    margin-bottom: ${spacing[props.$spacingMedium]};
  `}

  ${(props) =>
    isInteger(props.$spacingLarge) &&
    media.large`
    margin-bottom: ${spacing[props.$spacingLarge]};
  `}

  ${(props) =>
    isInteger(props.$spacingTop) &&
    `
    margin-top: ${spacing[props.$spacingTop]};
  `}

  ${(props) =>
    isInteger(props.$spacingTopMedium) &&
    media.medium`
    margin-top: ${spacing[props.$spacingTopMedium]};
  `}

  ${(props) =>
    isInteger(props.$spacingTopLarge) &&
    media.large`
    margin-top: ${spacing[props.$spacingTopLarge]};
  `}
`;

export default spacing;
