/* eslint-disable */
export const fontSizeValues = [
  0.75,   // 0    12px
  0.875,  // 1    14px
  1.0,    // 2    16px
  1.125,  // 3    18px
  1.25,   // 4    20px
  1.5,    // 5    24px
  2.0,    // 6    32px
  2.5,    // 7    40px
];

export const lineHeightValues = [
  1.35,   // 0 
  1.45,   // 1
  1.375,  // 2
  1.45,   // 3
  1.4,    // 4 
  1.35,   // 5 
  1.25,   // 6 
  1.2,    // 7
];

let sizes = []
for (let i = 0; i < fontSizeValues.length; i++) {
  sizes.push(`font-size: ${fontSizeValues[i]}rem; line-height: ${lineHeightValues[i]};`)
}

export default sizes;
